import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { MachineType } from "../../../Types/Entites/MachineType";
import { MachineDataType } from "../../../Types/Forms/MachineDataType";
import _ from "lodash";

export const saveMachineApi = createAsyncThunk(
  "machines/createMachine",
  async (data: MachineDataType, { rejectWithValue }) => {
    try {
      const response = await axios.post("machines/createMachine", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findMachinesApi = createAsyncThunk(
  "machines/findMachines",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`machines/findMachines`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findMachineById = createAsyncThunk(
  "machines/findMachineByIdApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`machines/findMachineByIdApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findMachinesStats = createAsyncThunk(
  "machines/test",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`machines/test`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findMachineDowntime = createAsyncThunk(
  "machines/downtime",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`machines/downtime`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateMachineById = createAsyncThunk(
  "machines/updateMachine",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`machines/updateMachine/${id}`, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deleteMachineApi = createAsyncThunk(
  "machines/deleteMachine",
  async (code: any, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`machines/deleteMachine/${code}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findNumberPanne = createAsyncThunk(
  "machines/numberPanne",
  async (code: null, { rejectWithValue }) => {
    try {
      const response = await axios.get(`machines/numberPanne`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const repairingTime = createAsyncThunk(
  "machines/reparingTime",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.get(`machines/reparingTime/${data.start}/${data.end}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface Machines {
  machinesList: MachineType[];
  machineListByCode: { [code: string]: MachineType[] };

  ListMachinesPerOdfs: {
    [Id: string]: MachineType[];
  };
  stats: any;
  downtime: any;
  numberPanne: any;
  repairingTime: any;
}

const initialState: Machines = {
  machinesList: [],
  machineListByCode: {},
  ListMachinesPerOdfs: {},
  stats: [],
  downtime: [],
  numberPanne: [],
  repairingTime: [],
};

export const machineSlice = createSlice({
  name: "machines",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveMachineApi.fulfilled, (state, action) => {
      let newMachine = action.payload.data;
      newMachine.allChecklistsPassed = null;
      state.machinesList.splice(0, 0, newMachine);
    });
    builder.addCase(updateMachineById.fulfilled, (state, action) => {
      const index = _.findIndex(state.machinesList, {
        id: action.payload.data.id,
      });
      if (index !== -1) {
        const updatedListMachines = [...state.machinesList];
        updatedListMachines[index] = {
          ...updatedListMachines[index],
          ...action.payload.data,
        };

        return {
          ...state,
          machinesList: updatedListMachines,
        };
      }

      return state;
    });

    builder.addCase(findMachinesApi.fulfilled, (state, action) => {
      const groupedByOdfs = _.groupBy(action.payload, "odf_code");
      const groupedByCode = _.groupBy(action.payload, "code");

      return {
        ...state,
        machinesList: action.payload,
        ListMachinesPerOdfs: groupedByOdfs,
        machineListByCode: groupedByCode,
      };
    });
    builder.addCase(findMachinesStats.fulfilled, (state, action) => {
      return {
        ...state,
        stats: action.payload,
      };
    });
    builder.addCase(findMachineDowntime.fulfilled, (state, action) => {
      return {
        ...state,
        downtime: action.payload,
      };
    });
    builder.addCase(findNumberPanne.fulfilled, (state, action) => {
      return {
        ...state,
        numberPanne: action.payload,
      };
    });
    builder.addCase(repairingTime.fulfilled, (state, action) => {
      return {
        ...state,
        repairingTime: action.payload,
      };
    });
  },
});

export const ListMachines = (state: RootState) => state.machines.machinesList;
export const stats = (state: RootState) => state.machines.stats;
export const downtimes = (state: RootState) => state.machines.downtime;
export const machineRepairingTime = (state: RootState) => state.machines.repairingTime;

export const numberPanneMachines = (state: RootState) =>
  state.machines.numberPanne;

export const ListMachinesByCode = (state: RootState) =>
  state.machines.machineListByCode;

export const ListMachinesPerOdfs = (state: RootState) =>
  state.machines.ListMachinesPerOdfs;

export default machineSlice.reducer;
