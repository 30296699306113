import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { useAppSelector } from "../../_App/Redux/hooks";
import Sidebar from "../SideBar/Sidebar";
import NotificationBar from "../SideBar/NotificationBar";
import { TicketsList } from "../../_App/Redux/Slices/tickets/ticketSlice";

const RestyledLayout = styled.div`
  ${(props) => {
    var style = ``;
    // check the position of the menu right, left up, down

    return style;
  }}

  #content .col-left {
    padding: 0;
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
    margin-right: 0px;
  }
`;

export default function Layout() {
  const notifications = useAppSelector(TicketsList);
  const todaysnotifs = () => {
    let sum = 0;
    notifications &&
      notifications.map((notification) => {
        if (notification.cloture === 0 || notification.affected_to === null)
          sum += 1;
      });
    return sum;
  };

  const [showNotificationBar, setShowNotificationBar] = useState(false);

  const toggleNotificationBar = () => {
    setShowNotificationBar(!showNotificationBar);
  };

  return (
    <RestyledLayout>
      <ToastContainer />
      <div
        className="dashboard-analytics admin-header"
        style={{
          color: "#888ea8",
          height: "100%",
          fontSize: "0.875rem",
          background: "#f1f2f3",
          overflowX: "hidden",
          overflowY: "auto",
          letterSpacing: "0.0312rem",
          fontFamily: "Quicksand, sans-serif",
        }}
      >
        <div className="main-container" id="container">
          <Sidebar />
          <div id="content" className="main-content">
            <div className="layout-px-spacing">
              <div className="">
                <div className="col-left">
                  <div className="col-left-content">
                    <div
                      className="toggle-notification-bar"
                      style={{
                        position: "absolute",
                        top: "26px",
                        right: "20px",
                        cursor: "pointer",
                      }}
                      onClick={toggleNotificationBar}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-bell"
                      >
                        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                      </svg>
                      <span
                        className="mail-badge badge badge-danger"
                        style={{ position: "absolute", top: -7 }}
                      >
                        {todaysnotifs()}
                      </span>
                    </div>
                    <div
                      className={`notification-bar ${
                        showNotificationBar ? "show" : ""
                      }`}
                    >
                      {showNotificationBar ? (
                        <div
                          className="toggle-notification-bar"
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "381px",
                            top: "24px",
                            zIndex: 10,
                          }}
                          onClick={() => setShowNotificationBar(false)}
                        >
                          <i className="fas fa-chevron-right"></i>
                        </div>
                      ) : null}
                      <NotificationBar />
                    </div>
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-wrapper col-xl-12">
          <div className="footer-section f-section-1"></div>
          <div className="footer-section f-section-2">
            <p className="">
              © 2024{" "}
              <a target="_blank" href="https://designreset.com">
                Clediss
              </a>{" "}
              Tous droits réservés.
            </p>
          </div>
        </div>
      </div>
    </RestyledLayout>
  );
}
