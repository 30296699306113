import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const InterventionPieChart = ({ data }: any) => {
  // Prepare data for the pie chart
  const chartData = {
    labels: data?.map((group: any) => group.groupe_name), // Group names
    datasets: [
      {
        label: "Nombre d'interventions",
        data: data?.map((group: any) => group.intervention_count || 0), // Intervention counts
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#FF5733",
          "#4CAF50",
          "#FFB300",
          "#008CBA",
          "#F44336",
          "#673AB7",
        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div>
      <h2>Nombre d'interventions par type</h2>
      <Pie data={chartData} />
    </div>
  );
};

export default InterventionPieChart;
