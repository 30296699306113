import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, Tooltip, Legend, CategoryScale, LinearScale } from "chart.js";

// Register Chart.js components
ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

const HorizontalBarChart = ({ data }: any) => {
  // Prepare data for the horizontal bar chart
  const chartData = {
    labels: data?.map((machine: any) => machine.machine_code), // Machine codes
    datasets: [
      {
        label: "Nombre d'interventions",
        data: data?.map((machine: any) => machine.intervention_count || 0), // Intervention counts
        backgroundColor: "#36A2EB",
        borderColor: "#1E88E5",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y" as const, // Horizontal bar chart
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top" as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => `${context.raw} interventions`,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Nombre d'interventions",
        },
      },
      y: {
        title: {
          display: true,
          text: "Machines",
        },
      },
    },
  };

  return (
    <div>
      <h2>Nombre d'interventions par machine</h2>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default HorizontalBarChart;
