import moment from "moment";
import React from "react";

type Machine = {
  machine_code: string;
  start: string; // Time format HH:mm:ss
  end: string; // Time format HH:mm:ss
};

type User = {
  user_code: string;
  user_name: string;
  machines: Machine[];
};
interface GanttChartProps {
  singleDay: any; // You can specify a more specific type instead of 'any'
  setSingleDay: React.Dispatch<React.SetStateAction<any>>; // Same here, define a more specific type if possible
  data: any;
}



const GanttChart: React.FC<GanttChartProps> = ({ singleDay, setSingleDay , data}) => {
  // Helper function to generate random color
const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Assign colors dynamically to machines
const machineColors: Record<string, string> = {};

data.forEach((user:any) => {
  user.machines.forEach((machine:any) => {
    if (!machineColors[machine.machine_code]) {
      machineColors[machine.machine_code] = generateRandomColor();
    }
  });
});

// Helper: Convert time string to minutes since 7 AM
const timeToMinutes = (time: string | null | undefined): number => {
  if (!time) {
    console.error("Invalid time value:", time); // Optional: Log an error for debugging
    return 0; // Default to 0 minutes if time is invalid
  }

  const [hours, minutes, seconds] = time.split(":").map(Number);
  return (hours - 7) * 60 + minutes + (seconds || 0) / 60; // Adjust to 7 AM
};


// Constants
const START_TIME = 0; // 07:00 in minutes (relative to 7 AM)
const END_TIME = 720; // 23:59 in minutes (relative to 7 AM)
const TOTAL_MINUTES = END_TIME - START_TIME;
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <div className="row date-filter-row">
        <div className="col-md-3 col-6 pb-2">
          <label htmlFor="end-date"></label>
          <input
            className="date-input form-control"
            type="date"
            id="end-date"
            value={singleDay}
            onChange={(e) =>
              setSingleDay(
                moment(e.currentTarget.value, "YYYY-MM-DD").format("YYYY-MM-DD")
              )
            }
          />
        </div>
      </div>
      <div
        style={{ display: "flex", position: "relative", marginBottom: "10px" }}
      >
        <div style={{ width: "200px", fontWeight: "bold" }}>Techniciens</div>
        <div
          style={{
            flex: 1,
            display: "grid",
            gridTemplateColumns: `repeat(15, 1fr)`, // Divide into 15 equal parts (approx 1 hour)
            borderBottom: "1px solid #ccc",
          }}
        >
          {[...Array(15)].map((_, i) => (
            <div key={i} style={{ textAlign: "center" }}>
              {i + 7} {/* Display hours starting from 7 */}
            </div>
          ))}
        </div>
      </div>

      {/* Rows for Users */}
      {data.map((user:any) => (
        <div
          key={user.user_code}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          {/* User Name */}
          <div style={{ width: "200px", fontWeight: "bold" }}>
            {user.user_name}
          </div>

          {/* Timeline */}
          <div
            style={{
              flex: 1,
              position: "relative",
              height: "40px",
              background: "#f5f5f5",
              border: "1px solid #ddd",
            }}
          >
            {/* Machine Activities */}
            {user.machines.map((machine:any) => {
              const startMinutes = timeToMinutes(machine.start);
              const endMinutes = timeToMinutes(machine.end);
              const left = (startMinutes / TOTAL_MINUTES) * 100;
              const width = ((endMinutes - startMinutes) / TOTAL_MINUTES) * 100;

              return (
                <div
                  key={machine.machine_code}
                  style={{
                    position: "absolute",
                    left: `${left}%`,
                    width: `${width}%`,
                    height: "100%",
                    backgroundColor: machineColors[machine.machine_code],
                    color: "#fff",
                    textAlign: "center",
                    lineHeight: "40px",
                    borderRadius: "4px",
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      ))}

      {/* Display machine colors with flexbox */}
      <div style={{ marginTop: "20px", fontWeight: "bold" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap", // Allow wrapping of items
            gap: "10px", // Space between items
          }}
        >
          {Object.entries(machineColors).map(([machineCode, color]) => (
            <div
              key={machineCode}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: color,
                  borderRadius: "50%",
                }}
              ></div>
              <span>{machineCode}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GanttChart;
