import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const RepairTimePieChart = ({ data }: any) => {
  // Prepare data for the pie chart
  const chartData = {
    labels: data?.map((machine: any) => machine.machine_code), // Machine codes
    datasets: [
      {
        label: "Temps total de réparation (heures)",
        data: data?.map((machine: any) => parseFloat(machine.total_repairing_time) || 0), // Repair times
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#FF5733",
          "#4CAF50",
          "#FFB300",
          "#008CBA",
          "#F44336",
          "#673AB7",
          "#795548",
          "#9E9E9E",
          "#607D8B",
        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div>
      <h2>Temps total de réparation par machine</h2>
      <Pie data={chartData} />
    </div>
  );
};

export default RepairTimePieChart;

